import React from 'react';
import "./portfolio.css";
import { CTA } from '../../components';
import revel from '../../assets/revel-min.jpg';
import j308 from '../../assets/j308-min.jpg';
import nes from '../../assets/nes-min.jpg';
import vasco from '../../assets/vasco-medical-min.jpg';

// Card,

// const cardData = [
//   {
//     script: '',
//     name: '',
//   },
//   {
//     script: '',
//     name: '',
//   },
//   {
//     script: '',
//     name: '',
//   },
//   {
//     script: '',
//     name: '',
//   },
// ]

const Portfolio = () => {
  return (
    <div className='portfolio' id="portfolio">
      <div className="portfolio-heading">
        <h1>Have a look for yourself</h1>
        <p>Experience virtual tours the way a customer would</p>
      </div>
      <div className="portfolio-cards">
      
        <div className="tour-nav">
        <a class="tour" href="https://kuula.co/share/collection/7FN30?logo=1&info=0&logosize=200&fs=1&vr=1&zoom=1&gyro=0&initload=0&thumbs=1" target= '_blank' rel="noreferrer">
          <div className="card-container-card">
              <div className="card-container-card-img">
                  <img src={revel} alt="" />
              </div>
            <div className="card-container-card-title">
              <p>Revel x Plato</p>
            </div>
          </div>
          </a>
        </div>


        <div className="tour-nav">
        <a class="tour" href="https://kuula.co/share/collection/7Fmvj?logo=1&info=0&logosize=200&fs=1&vr=1&zoom=1&gyro=0&initload=0&thumbs=1" target= '_blank' rel="noreferrer">
          <div className="card-container-card">
              <div className="card-container-card-img">
                  <img src={j308} alt="" />
              </div>
            <div className="card-container-card-title">
              <p>Juliette 308</p>
            </div>
          </div>
          </a>
        </div>


        <div className="tour-nav">
        <a class="tour" href="https://kuula.co/share/collection/7FNy9?logo=1&info=0&logosize=200&fs=1&vr=1&zoom=1&gyro=0&initload=0&thumbs=1" target= '_blank' rel="noreferrer">
          <div className="card-container-card">
              <div className="card-container-card-img">
                  <img src={nes} alt="" />
              </div>
            <div className="card-container-card-title">
              <p>Nes on Lambert</p>
            </div>
          </div>
          </a>
        </div>

        <div className="tour-nav">
        <a class="tour" href="https://kuula.co/share/collection/7FY5Q?logo=1&info=0&logosize=200&fs=1&vr=1&zoom=1&gyro=0&initload=0&thumbs=1" target= '_blank' rel="noreferrer">
          <div className="card-container-card">
              <div className="card-container-card-img">
                  <img src={vasco} alt="" />
              </div>
            <div className="card-container-card-title">
              <p>Vasco Medical Centre</p>
            </div>
          </div>
          </a>
        </div>




      </div>
      < CTA />
    </div>
  )
}

export default Portfolio