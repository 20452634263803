import React from 'react';
import "./features.css";
import { Feature } from '../../components';

const featuresData = [
  {
    title: '50%',
    text: "of potential customers rely on virtual tours for research and decision-making.",
  },
  {
    title: '54%',
    text: "of customers skip rental spaces that don't have virtual tours",
  },
  {
    title: '75%',
    text: 'of customer decision-making is affected by a virtual tour',
  },
  {
    title: '87%',
    text: 'more views than properties without virtual tours',
  },
]

const Features = () => {
  return (
    <div className="features" id="features">
      <div className="features-heading">
        <h1>A unique opportunity for rentals, venues, art galleries, restaurants, real estate, business-owners, and more.</h1>
        <p>Cut down on decision-making time and showcase your venue's best attributes.</p>
      </div>
      <div className="features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
    </div>
  )
}

export default Features