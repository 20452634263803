import './app.css';
// import { BrowserRoute, Routes, Route } from 'react-router-dom';

// Blog, Testimonials,

import { Footer,  Services, Portfolio, Header, Features } from './containers';
import { Navbar } from './components';
import "./app.css"

export default function App() {
  return (

    <div className="App">
      <div>
        < Navbar />
        < Header />
        < Features />
        < Services />
        < Portfolio />
        < Footer />
      </div>
    </div>
  );
}
