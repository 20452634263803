import React from 'react';
import "./cta.css";

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

const CTA = () => {
  return (
    <div className='cta'>
      <div className="cta-content">
        <h3>What's next?</h3>
        <p>Contact us and lets get your space off the ground (literally)</p>
      </div>
      <div className="cta-button">
      <Mailto email="info@expansevirtual.co.za" subject="I want to book a consultation" body="">
      <button type='button'>Contact Us</button>
          </Mailto>
      </div>
    </div>
  )
}

export default CTA