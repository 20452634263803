import React from 'react';
import { Service } from '../../components';
import "./services.css";

const Services = () => {
  return (
    <div className='services section-margin' id='services'>
      <div className="services-feature"> 
        <h1>Services</h1> 
      </div>
      <div className="services-container">
        <Service id="services-feature" title="360 Virtual Tours" 
        text="Each tour includes on-site image capturing, and post-production. 
        We'll discuss the specific needs of your location before coming to site to capture your space in the way it deserves to be seen. 
        Then we add in the goodies that revolutionise your customer's experience by showcasing your space's unique selling points."/>
        <Service 
        id="services-feature" 
        title="Hosting" 
        text="We make sure your virtual tour is always online and available to your customers. 
        All you have to do is embed the code that we provide, 
        or include a link to the tour on your site or listing to show it off."/>
      </div>
    </div>
  )
}

export default Services