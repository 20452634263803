import React from 'react';
import { BsEnvelope } from 'react-icons/bs';
import { Policy } from '../../components';
import "./footer.css";

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-heading">
        <h1>Expanse Virtual</h1>
      </div>
      <div className="footer-links">
        {/* <div className="footer-links-div">
          <h4>Links</h4>
          <p>Home</p>
          <p>Services</p>
          <p>Features</p>
          <p>Library</p>
          <p>Privacy Policy</p>
        </div> */}
        <div className="footer-links-div">
          <h4>Get in touch</h4>
          {/* <p> <BsTelephone />: +27 72 097 8350 or +27 83 276 1944 </p> */}
          <Mailto email="info@expansevirtual.co.za" subject="" body="">
          <p> <BsEnvelope />: info@expansevirtual.co.za</p>
          </Mailto>
          < Policy />
      </div>
      </div>
      <div className="footer-copyright">
        <p>All Rights Reserved | Copyright © 2023 Expanse Virtual. </p>
      </div>
    </div>
  )
}

export default Footer