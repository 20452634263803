import React from 'react';
import './service.css';

const Service = ({ title, text }) => (
  <div className="services-container-service">
    <div className="services-container-service-title">
      <h1>{title}</h1>
    </div>
    <div className="services-container-service-text">
      <p>{text}</p>
    </div>
  </div>
);

export default Service;