import React from 'react';
import "./header.css";
import pano from '../../assets/updatedpano.svg';

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};
// Virtual tours are a powerful marketing tool for your website or property listing that will generate more interest in your space and more confirmed customers
const Header = () => {
  return (
    <div className="header" id="home">
        <div className="header-content">
          <h1>We make virtual tours that turn potential customers into confirmed bookings</h1>
          <p>Not everyone can view your location in person before making their decision. If they can't come to you, we'll take you to them.</p>
          <div className='header-content-input'>
            {/* <input type="email" placeholder='Your Email Address...' /> */}
            <Mailto email="info@expansevirtual.co.za" subject="I want to book a consultation" body="">
      <button type='button'>Contact Us</button>
          </Mailto>
          </div>
        </div>
        <div className='header-image'>
            <img src={pano} alt="" />
        </div>
    </div>
  )
}

export default Header