import React from 'react';
// , { useState }
// import { RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import "./navbar.css";
// import { NavLink } from "react-router-dom"; 

const Menu = () => (
  <>
  {/* <p><a href="#services"></a>Services</p>
  <p><a href="#features"></a>Features</p>  
  <p><a href="#portfolio"></a>Examples</p>
  {/* <p><a href="#testimonials"></a>Testimonials</p>
  <p><a href="#cta"></a>Contact</p> */}
  </>
)

const Navbar = () => {
  // const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar">
      <div className="navbar-links">
      <div className="navbar-links-logo">
          {/* <a href='#header'></a> */}
          <h1>Expanse Virtual</h1>
      </div>
        <div className="navbar-links-container">
          < Menu />
        </div>
      </div>
      {/* <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color='#ff0a7f' size={27} onClick={( ) => setToggleMenu(false)} />
          : <RiMenu3Line color='#00686a' size={27} onClick={( ) => setToggleMenu(true)} />
          }
          {toggleMenu && (
            <div className="navbar-menu-container scale-up-center">
              <div className="navbar-menu-container-links">
                < Menu />
              </div>
            </div>
          )}
        </div> */}
    </div>
  )
}

export default Navbar